<template>
  <el-main class="rh-form">
    <form-hcactiviteedit :routetolist="{ name: 'hcactivites' }" />
  </el-main>
</template>
<script>
import FormHcactiviteedit from "@/components/rh/formulaires/FormHcactiviteedit";

export default {
  components: { "form-hcactiviteedit": FormHcactiviteedit },
};
</script>
